import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import GetApi from "../Services/GetApi";
import { UtilsJson } from "../utils/UtilsJson";

function CategoryList() {
  const [category, setcategory] = useState([]);
  useEffect(() => {
    GetApi("category").then((e) => {
      setcategory(e.responceData.data);
    });
  }, []);

  return (
    <div className="p-2 sm:py-5 ">
      <div className=" mt-0 text-center sm:m-5">
        <h1 className="block text-center text-red-900 text-2xl font-extrabold xl:inline sm:text-6xl mb-4 sm:mb-10 font-dancing capitalize lg:text-6xl ">
          <span className="underline text-2xl sm:text-6xl  decoration-red-900 font-dancing">
            Varieties
          </span>
        </h1>
      </div>

      <div className="grid grid-cols-3 sm:grid-cols-4 gap-5 sm:gap-6">
        {!category.length ? (
          <>
            <div>
                <div className="placeholder-content m-2 sm:h-40 h-32 border-r-2"></div>
                <div className="placeholder-content m-2 sm:h-10 h-5 border-r-2"></div>
            </div> 
            <div>
                <div className="placeholder-content m-2 sm:h-40 h-32 border-r-2"></div>
                <div className="placeholder-content m-2 sm:h-10 h-5 border-r-2"></div>
            </div> 
         
          </>
        ) : (
          category.map((e) => ((e.status == '1')?
            <NavLink to={"shop/" + e.category_no} key={Math.random()}>
              <div className="m-2 items-center max-w-sm mx-auto overflow-hidden shadow-lg">
                <div className="w-full  sm:h-64 bg-white bg-center bg-cover rounded-t-lg hover:rounded-3xl">
                  {" "}
                  <img
                    className="bg-cover bg-center rounded-t-lg  sm:h-64 w-full"
                    src={UtilsJson.baseUrl + "productimg/" + e.category_image}
                    alt=""
                  />
                </div>
                <div className=" font-dancing w-full overflow-hidden bg-white z-50 shadow-lg rounded-b-lg  ">
                  <h3 className="py-2 w-full font-extrabold text-sm sm:text-xl font-dancing tracking-wide text-center text-red-800  dark:text-red-800">
                    {e.category_name}
                  </h3>
                </div>
              </div>
            </NavLink> : ""
          ))
        )}
      </div>
    </div>
  );
}

export default CategoryList;
