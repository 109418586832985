import Footer from "./Footer";

function ShippingDelivery() {
  return (
    <>
      <div className="sm:py-16 mx-auto md:px-24 lg:px-8 lg:py-20 py-[64px] px-5  text-gray-700">
        <h1 className="text-5xl font-extrabold text-center py-5 font-dancing text-red-900">
          Shipping & Delivery
        </h1>

        <p className="mt-2 text-justify">
          Unless the shipment charges are shown separately, all the products are
          inclusive of delivery charges.
        </p>
        <p className="mt-2 text-justify">
          <strong> </strong>
        </p>
        <p className="mt-2 text-justify">
          <strong>
            Why does the delivery date not correspond to the delivery timeline
            of X-Y business days?
          </strong>
        </p>
        <p className="mt-2 text-justify">
          All the Hand delivered product like cake, sweets &amp; are delivered
          on the selected delivery date &amp; time including Sundays &amp;
          Public holidays, Festivals. For all other product It is possible that
          the Seller or our courier partners have a holiday between the day your
          placed your order and the date of delivery, which is based on the
          timelines shown on the product page. In this case, we add a day to the
          estimated date. Some courier partners and Sellers do not work on
          Sundays and this is factored in to the delivery dates.
        </p>
        <p className="mt-2 text-justify"></p>
        <p className="mt-2 text-justify">
          <strong>What is the estimated delivery time?</strong>
        </p>
        <p className="mt-2 text-justify">
          For the Hand delivered product like cake, sweets &amp; perishable
          products same day delivery is available. For other product Sellers
          generally procure and ship the items within the time specified on the
          product page. Business days exclude public holidays and Sundays.
        </p>
        <p className="mt-2 text-justify">
          <strong> </strong>
        </p>
        <p className="mt-2 text-justify">
          <strong>
            Estimated delivery time depends on the following factors:
          </strong>
        </p>
        <p className="mt-2 text-justify">The Seller offering the product</p>
        <p className="mt-2 text-justify">
          Product's availability with the Seller
        </p>
        <p className="mt-2 text-justify">
          The destination to which you want the order shipped to and location of
          the Seller.
        </p>
        <p className="mt-2 text-justify"> </p>
        <p className="mt-2 text-justify">
          <strong>
            Are there any hidden costs (sales tax, octopi etc.) on items sold by
            Sellers on SRM Sweets and Cakes?
          </strong>
        </p>
        <p className="mt-2 text-justify">
          There are NO hidden charges when you make a purchase on Sri
          SRM Sweets & Cake. List prices are final and all-inclusive. The
          price you see on the product page is exactly what you would pay.
        </p>
        <p className="mt-2 text-justify">
          Delivery charges are not hidden charges and are charged (if at all)
          extra depending on the Seller's shipping policy.
        </p>
        <p className="mt-2 text-justify"></p>
        <p className="mt-2 text-justify">
          <strong>
            Why does the estimated delivery time vary for each seller?
          </strong>
        </p>
        <p className="mt-2 text-justify">
          You have probably noticed varying estimated delivery times for sellers
          of the product you are interested in. Delivery times are influenced by
          product availability, geographic location of the Seller, your shipping
          destination and the courier partner's time-to-deliver in your
          location.
        </p>
        <p className="mt-2 text-justify">
          <strong> </strong>
        </p>
        <p className="mt-2 text-justify">
          <strong>Seller does not/cannot ship to my area. Why?</strong>
        </p>
        <p className="mt-2 text-justify">
          Whether your location can be serviced or not depends on
        </p>
        <p className="mt-2 text-justify">
          Your location may be outside city, Remote area &amp; product may not
          be hand delivered
        </p>
        <p className="mt-2 text-justify">
          Legal restrictions, if any, in shipping particular products to your
          location
        </p>
        <p className="mt-2 text-justify">
          The availability of reliable courier partners in your location
        </p>
        <p className="mt-2 text-justify">
          At times Sellers prefer not to ship to certain locations. This is
          entirely at their discretion.
        </p>
        <p className="mt-2 text-justify">
          <strong> </strong>
        </p>
        <p className="mt-2 text-justify">
          <strong>Why is the COD option not offered in my location?</strong>
        </p>
        <p className="mt-2 text-justify">
          Availability of COD depends on the ability of our courier partner
          servicing your location to accept cash as payment at the time of
          delivery.
        </p>
        <p className="mt-2 text-justify">
          Our courier partners have limits on the cash amount payable on
          delivery depending on the destination and your order value might have
          exceeded this limit.
        </p>
        <p className="mt-2 text-justify">
          <strong> </strong>
        </p>
        <p className="mt-2 text-justify">
          <strong>
            I need to return an item, how do I arrange for a pick-up?
          </strong>
        </p>
        <p className="mt-2 text-justify">
          Returns are easy. Contact us to initiate a return. You will receive a
          call explaining the process, once you have initiated a return. For
          more queries visit Return policy.
        </p>
        <p className="mt-2 text-justify">
          <strong> </strong>
        </p>
        <p className="mt-2 text-justify">
          <strong>
            After placing the order when I will receive the product?
          </strong>
        </p>
        <p className="mt-2 text-justify">
          For hand delivered product such us cake, sweet perishable products
          same day delivery is available usually 2-5 business days, 2-7 business
          days, 5-10 business days in areas where standard courier service is
          available. For other areas, orders will be sent by Registered Post
          through the Indian Postal Service which may take 1-2 weeks depending
          on the location.
        </p>
        <p className="mt-2 text-justify">
          The Seller might not have the item in stock but can procure it when an
          order is placed for the item. The delivery time will depend on the
          estimated procurement time and the estimated shipping time to your
          location.
        </p>
        <p className="mt-2 text-justify">
          <strong> </strong>
        </p>
        <p className="mt-2 text-justify">
          <strong>'Preorder' or 'Forthcoming'</strong>
        </p>
        <p className="mt-2 text-justify">
          Such items are expected to be released soon and can be pre-booked for
          you. The item will be shipped to you on the day of its official
          release launch and will reach you in 2 to 6 business days. The
          Preorder duration varies from item to item. Once known, release time
          and date is mentioned. (Eg. 5th May, August 3rd week)
        </p>
        <p className="mt-2 text-justify">
          <strong> </strong>
        </p>
        <p className="mt-2 text-justify">
          <strong>'Out of Stock'</strong>
        </p>
        <p className="mt-2 text-justify">
          Currently, the item is not available for sale. Use the 'Notify Me'
          feature to know once it is available for purchase.
        </p>
        <p className="mt-2 text-justify">
          <strong> </strong>
        </p>
        <p className="mt-2 text-justify">
          <strong>'Back In Stock Soon'</strong>
        </p>
        <p className="mt-2 text-justify">
          The item is popular and is sold out. You can however 'book' an order
          for the product and it will be shipped according to the timelines
          mentioned by the Seller.
        </p>
        <p className="mt-2 text-justify">
          <strong> </strong>
        </p>
        <p className="mt-2 text-justify">
          <strong>'Temporarily Unavailable'</strong>
        </p>
        <p className="mt-2 text-justify">
          The product is currently out of stock and is not available for
          purchase. The product could to be in stock soon. Use the 'Notify Me'
          feature to know when it is available for purchase.
        </p>
      </div>
      <Footer></Footer>
    </>
  );
}

export default ShippingDelivery;
