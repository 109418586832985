import React, { Fragment, useContext, useState, useEffect } from "react";
import { Context } from "../App";
import { Disclosure, Menu, Transition, Dialog } from "@headlessui/react";
import { MenuIcon, ShoppingBagIcon, XIcon } from "@heroicons/react/outline";
import logo from "./../img/srm-logo.png";
import { NavLink } from "react-router-dom";
import GetApi from "../Services/GetApi";
import PostApi from "../Services/PostApi";
import { useLocation, useNavigate } from "react-router-dom";
import { Detector } from "react-detect-offline";
import { ToastContainer } from "react-toastify";
import loginBg from "./../img/login-bg.jpg";
import OtpInput from 'react-otp-input';

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const location = useLocation();
  let navigate = useNavigate();
  const navigation = [
    {
      name: "Home",
      href: "/",
      current: location.pathname === "/" ? true : false,
    },
    {
      name: "About US",
      href: "/about-us",
      current: location.pathname === "/about-us" ? true : false,
    },
    {
      name: "Shop",
      href: "/shop",
      current: location.pathname === "/shop" ? true : false,
    },
    // {
    //   name: "Contact Us",
    //   href: "/contact-us",
    //   current: location.pathname === "/contact-us" ? true : false,
    // }
  ];

  const { loginPopUpView, atcProduct } = useContext(Context);
  const [stateloginPopUpView, setstateloginPopUpView] = loginPopUpView;
  const [loginStatus, setloginStatus] = useState(false);
  const { CheckoutView } = useContext(Context);
  const [, setstateCheckoutView] = CheckoutView;
  const { customerID } = useContext(Context);
  const [statecustomerID, setstatecustomerID] = customerID;
  const [statatcProduct, setstateatcProduct] = atcProduct;
  const [profilePopUp, setprofilePopUp] = useState(false);
  const [Otp, setOtp] = useState("");
  const [optError, setOtpError] = useState(false);
  const [flag, setFlag] = useState(true);

  const updateOpt = (code) => {
    setOtp(code);
  };

  const PHONE = React.useRef();
  const sendOTP = () => {
    const PHONEVALUE = PHONE.current.value;
    if (PHONEVALUE && PHONEVALUE.length === 10) {
      document.getElementsByClassName("ph-success")[0].innerHTML = "";
      document.getElementsByClassName("ph-error")[0].innerHTML = "";
      setloginStatus(true);
      setOtp("");
      GetApi("custLogin?phone_number=" + PHONEVALUE).then((e) => {
        if (e.responceData.data.status_code === "200") {
          document.getElementsByClassName("ph-success")[0].innerHTML =
            e.responceData.data.Message;
          setloginStatus(true);
        } else {
          document.getElementsByClassName("ph-error")[0].innerHTML =
            e.responceData.data.Message;
        }
      });
    } else {
      setloginStatus(false);
      document.getElementsByClassName("ph-error")[0].innerHTML = "Please fill the valid phone number"
    }
  };

  const login = async () => {
    setOtpError('');
    setFlag(false);
    const PHONEVALUE = PHONE.current.value;
    let data = {
      customer_phone: PHONEVALUE,
      otp: Otp
    };
    if (flag) {
      await PostApi("custLogin", data).then((responce) => {
        setFlag(true);
        if (responce?.responcePostData?.data?.status_code === "200") {
          setOtp('');
          setstatecustomerID(
            responce.responcePostData.data.Message[0].customer_no
          );
          localStorage.setItem(
            "customer",
            responce.responcePostData.data.Message[0].customer_no
          );
          localStorage.setItem(
            "customerDetails",
            JSON.stringify(responce.responcePostData.data.Message[0])
          );
          GetApi(
            "atc/" + responce.responcePostData.data.Message[0].customer_no
          ).then((e) => {
            setstateatcProduct(e.responceData.data);
          });
          setstateloginPopUpView(false);
        } else {
          setOtpError(responce.responcePostData.data.Message);
        }
      });
    }
  };

  if (flag && Otp.length === 4) {
    setFlag(false);
    login();
  }

  const logout = () => {
    localStorage.clear();
    setloginStatus(false);
    setprofilePopUp(false);
    setstatecustomerID(null);
    setstateatcProduct([]);
    navigate("/");
  };

  const countProducts = (list) => {
    let count = 0;
    list.forEach((element) => {
      count += Number(element.quantity);
    });
    return count;
  };

  const checkout = () => {
    customerID[0] ? setstateCheckoutView(true) : setstateloginPopUpView(true);
  };

  const loginCheck = () => {
    customerID[0] ? setprofilePopUp(true) : setstateloginPopUpView(true);
  };

  return (
    <>
      <ToastContainer />
      <Detector
        render={({ online }) => (
          <div
            className={
              online
                ? ""
                : "bg-red-600 text-white text-center sticky top-0 z-50"
            }
          >
            {online ? "" : "You are currently offline"}
          </div>
        )}
      />

      <Transition.Root show={stateloginPopUpView}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          onClose={() => setstateloginPopUpView(false)}
        >
          <div className="flex justify-center min-h-screen items-center  bg-opacity-25   text-center sm:block sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="hidden fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity md:block" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle "
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              {loginPopUpView ? (
                <div className="flex text-base text-left transform transition bg-white shadow-md rounded md:inline-block md:align-middle w-full  md:w-[70%]">
                  <div className="flex flex-row w-full">
                    <div className="w-1/2 hidden md:block">
                      <img
                        src={loginBg}
                        alt=""
                        className="w-full h-full object-center object-cover"
                      />
                    </div>
                    <div className="w-full md:w-1/2 flex flex-col justify-center">
                      <h3 className="p-3 pb-0 pt-5 text-sm text-center text-gray-700 leading-tight">
                        One Step to Login & Signup make it simple.
                      </h3>
                      <h3 className="text-sm text-center text-gray-700 leading-tight">
                        Just fill your phone number
                      </h3>
                      <form className="px-8 pt-2 pb-8">
                        <div className="mb-4">
                          <label className="hidden text-gray-700 text-xs mb-2">
                            Enter your phone number to continue
                          </label>
                          <input
                            ref={PHONE}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="phone_number"
                            type="number"
                            placeholder=""
                            minLength={10}
                            maxLength={10}
                          />

                          <div className="text-xs mt-1 text-red-500 ph-error"></div>
                          <div className="text-xs mt-1 text-green-500 ph-success"></div>
                        </div>

                        {loginStatus ? (
                          <div className="mb-6">
                            <label className="block text-gray-700 text-xs mb-2">
                              Please enter the OTP sent
                            </label>
                            <OtpInput
                              value={Otp}
                              onChange={updateOpt}
                              numInputs={4}
                              separator={<span style={{ width: "8px" }}></span>}
                              isInputNum={true}
                              shouldAutoFocus={true}
                              inputStyle={{
                                paddingLeft: "0.75rem",
                                paddingRight: "0.75rem",
                                border: "1px solid transparent",
                                borderRadius: "0.25rem",
                                marginBottom: "0.75rem",
                                boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
                                width: "38px",
                                height: "38px",
                                fontSize: "12px",
                                color: "#000",
                                fontWeight: "400",
                                caretColor: "blue"
                              }}
                              focusStyle={{
                                border: "1px solid #CFD3DB",
                                outline: "none"
                              }}
                            />
                            <div className="text-xs mt-1 text-red-500 otp-error">{optError}</div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="items-center justify-between">
                          {!loginStatus ? (
                            <button
                              onClick={() => sendOTP()}
                              className="bg-blue-500 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                              type="button"
                            >
                              Request OTP
                            </button>
                          ) : (
                            <button
                              onClick={() => login()}
                              className="bg-blue-500 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                              type="button"
                            >
                              Verify
                            </button>
                          )}

                          {loginStatus ? (
                            <div className="text-center">
                              <label className="text-black pt-2 text-xs text-center">
                                Not received your code?{" "}
                              </label>
                              <button
                                onClick={() => sendOTP()}
                                className="text-blue-500 text-xs font-bold"
                                type="button"
                              >
                                Resend OTP
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                          <div
                            className=" text-xs text-red-500 mt-2 text-center cursor-pointer"
                            onClick={() => setstateloginPopUpView(false)}
                          >
                            Cancel
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Disclosure
        as="nav"
        className="fixed bg-white h-14 p-1 sm:h-auto sm:p-0 w-full z-50"
      >
        {({ open }) => (
          <>
            <div className="mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex items-center sm:justify-between h-12 sm:h-16">
                <div className="inset-y-0 left-0  items-center hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center p-2 rounded-md text-gray-800 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex justify-center sm:items-stretch sm:justify-start">
                  <div className="flex-shrink-0 flex items-center">
                    <img
                      className="block lg:hidden h-12 w-auto"
                      src={logo}
                      alt="Workflow"
                    />
                    <img
                      className="hidden lg:block h-16 w-auto"
                      src={logo}
                      alt="Workflow"
                    />
                  </div>
                  <div className="hidden sm:block mt-2 sm:ml-6">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <NavLink
                          key={Math.random()}
                          to={item.href}
                          activeclassname="active"
                          className="text-red-700  hover:bg-red-900 hover:text-white px-3 py-2 rounded-md text-2xl first-letter:font-bold font-dancing"
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute hidden sm:flex inset-y-0 right-0  items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {statecustomerID !== null ? (
                    <button
                      type="button"
                      className="p-1 rounded-full text-white hover:text-white focus:outline-none  focus:ring-offset-gray-800 focus:ring-white"
                    >
                      <span className="sr-only">View notifications</span>
                      <span
                        className="relative inline-block"
                        onClick={() => checkout()}
                      >
                        <ShoppingBagIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                        <span className="absolute top-1 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-gold-900 rounded-full">
                          {countProducts(statatcProduct)}
                        </span>
                      </span>
                    </button>
                  ) : (
                    ""
                  )}

                  {statecustomerID === null ? (
                    <>
                      <span className="relative cursor-pointer text-red-800"  onClick={() => {
                          setstateloginPopUpView(true);
                          setloginStatus(false);
                        }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          width="25"
                          height="25"
                          viewBox="0 0 24 24"
                          className="inline-block mb-1 relative"
                          stroke="currentColor"
                          strokeWidth="1"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                          />
                        </svg>
                        <span className="absolute top-1 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                          {countProducts(statatcProduct)}
                        </span>
                      </span>
                      {/* <button
                        onClick={() => {
                          setstateloginPopUpView(true);
                          setloginStatus(false);
                        }}
                        type="button"
                        className="p-1 px-4 text-[10px]  sm:text-[14px] rounded-sm ml-5 bg-gold-900 text-white hover:text-white focus:outline-none  focus:ring-offset-gray-800 focus:ring-white"
                      >
                        Login
                      </button> */}
                    </>
                  ) : (
                    <Menu as="div" className="ml-3 relative">
                      <div>
                        <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                          <span className="sr-only">Open user menu</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-7 w-7 rounded-full bg-white p-1"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                            />
                          </svg>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <NavLink
                                to="/profile"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Your Profile
                              </NavLink>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <NavLink
                                to="/order-list"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Orders
                              </NavLink>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <div
                                onClick={() => logout()}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Sign out
                              </div>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gold-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <section
        id="bottom-navigation"
        className="block sm:hidden fixed inset-x-0 bottom-0 z-10 bg-white text-red-600 shadow"
      >
        <div id="tabs" className="flex justify-between">
          <NavLink
            key={Math.random()}
            to="/"
            activeclassname="active"
            className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1"
          >
            <div className=" text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                className="inline-block mb-1"
                stroke="currentColor"
                strokeWidth="1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>
            </div>
            <span className="tab tab-home block text-xs">Home</span>
          </NavLink>
          <NavLink
            key={Math.random()}
            to="/about-us"
            className="w-full  focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              width="25"
              height="25"
              viewBox="0 0 24 24"
              className="inline-block mb-1"
              stroke="currentColor"
              strokeWidth="1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
              />
            </svg>
            <span className="tab tab-kategori block text-xs">About Us</span>
          </NavLink>
          <NavLink
            key={Math.random()}
            to="/shop"
            className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              width="25"
              height="25"
              viewBox="0 0 24 24"
              className="inline-block mb-1"
              stroke="currentColor"
              strokeWidth="1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
              />
            </svg>
            <span className="tab tab-explore block text-xs">Product</span>
          </NavLink>
          <div
            key={Math.random()}
            onClick={() => checkout()}
            className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1"
          >
            <span className="relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                className="inline-block mb-1 relative"
                stroke="currentColor"
                strokeWidth="1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                />
              </svg>
              <span className="absolute top-1 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                {countProducts(statatcProduct)}
              </span>
            </span>
            <span className="tab tab-whishlist block text-xs">Cart</span>
          </div>

          <div
            key={Math.random()}
            className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1 "
            onClick={() => {
              loginCheck(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              width="25"
              height="25"
              viewBox="0 0 24 24"
              className="inline-block mb-1"
              stroke="currentColor"
              strokeWidth="1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span className="tab tab-account block text-xs">Account</span>
          </div>
        </div>
      </section>

      <Transition.Root show={profilePopUp}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          onClose={() => setprofilePopUp(false)}
        >
          <div className="flex min-h-screen items-end w-screen  bg-opacity-25 text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              className={"w-full"}
            >
              <div className="flex text-base text-left transform transition  bg-white shadow-md rounded-tr rounded-tl w-full md:inline-block md:max-w-2xl md:px-4  md:align-middle lg:max-w-4xl">
                <div className="w-full p-2">
                  <div className="text-center w-full">
                    <p className="border-t-2 border-gray-300">{"  "}</p>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/profile");
                      setprofilePopUp(false);
                    }}
                    className={"flex px-4 py-2 text-sm text-gray-700"}
                  >
                    <span className="pr-3">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                      </svg>
                    </span>
                    Profile
                  </div>
                  <div
                    onClick={() => {
                      navigate("/order-list");
                      setprofilePopUp(false);
                    }}
                    className={"flex px-4 py-2 text-sm text-gray-700"}
                  >
                    <span className="pr-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                        />
                      </svg>
                    </span>
                    Orders
                  </div>

                  <div
                    onClick={() => logout()}
                    className={"flex px-4 pt-2 pb-4 text-sm text-gray-700"}
                  >
                    <span className="pr-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                        />
                      </svg>
                    </span>
                    Sign out
                  </div>
                  <div
                    onClick={() => setprofilePopUp(false)}
                    className={
                      "flex justify-center  px-4 py-2 text-sm border border-red-500 text-red-700 text-center"
                    }
                  >
                    <span className="pr-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </span>
                    Cancel
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
