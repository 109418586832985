import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import bg1 from "./../img/SVS-1.png";
import bg2 from "./../img/bg-2.jpg";
import bg3 from "./../img/bg-1.jpg";
import GetApi from "../Services/GetApi";
import Footer from "./Footer";

function AboutUsMin() {
  const location = useLocation();
  const [details, setDetails] = useState([]);
  useEffect(() => {
    GetApi("settings").then((e) => {
      setDetails(e.responceData.data[0])
    })
  }, []);
  return (
    <>
      <div className={
        location.pathname === "/" || !location.pathname || typeof (location.pathname) == 'undefined'
          ? "px-4 md:py-16 py-5 mx-auto md:px-24 lg:px-8 lg:py-20  hidden sm:block"
          : "sm:py-16 mx-auto md:px-24 lg:px-8 lg:py-20 py-[64px] px-5 "
      }
      >
        <div className="grid gap-10 lg:grid-cols-2">
          <div className="flex flex-col items-center md: text-center justify-center md:pr-8 xl:pr-0 ">
            <div className="flex items-center justify-center text-center   p-0 m-0 py-5 rounded-full bg-white">
              <span className="inline-block font-extrabold text-red-900 text-3xl sm:text-5xl font-dancing ">
                25<sup className="text-xl sm:text-2xl font-dancing">th</sup>
              </span>
            </div>
            <div className="max-w-xl ">
              <h2 className="max-w-lg mb-1 sm:mb-3 font-sans text-xl font-bold tracking-tight font-dancing  text-gray-900 sm:text-4xl sm:leading-none">
                Year in the Sweet <br className="hidden md:block" />
                Industry
              </h2>
              <p className="text-xl text-gray-800 md:text-xl text-justify font-dancing">
                {details.aboutus ? <>Welcome to SRM Sweets and Cakes, where tradition meets taste in the heart of India. With 25 years of culinary excellence, our brand has become a household name, synonymous with quality, innovation, and authentic flavours.
                  <br /><br />
                  At SRM Sweets and Cakes, we pride ourselves on crafting a delightful array of confections that cater to every palate. Our extensive menu includes a wide variety of traditional Indian sweets, meticulously prepared using time-honoured recipes and the finest ingredients. From the rich and creamy textures of our Kaju Katli to the aromatic and syrupy goodness of our Gulab Jamuns, every bite is a testament to our commitment to quality and authenticity.
                  <br /><br />
                  Join us in celebrating 25 years of delicious heritage at SRM Sweets and Cakes. Indulge in our creations, and let us be a part of your sweetest memories.</> :
                  <div>
                    <div className="placeholder-content placeholder-content-blue m-1 h-5 border-r-2"></div>
                    <div className="placeholder-content placeholder-content-blue m-1 h-5 border-r-2"></div>
                    <div className="placeholder-content placeholder-content-blue m-1 h-5 border-r-2"></div>
                    <div className="placeholder-content placeholder-content-blue m-1 h-5 border-r-2"></div>
                    <div className="placeholder-content placeholder-content-blue m-1 h-5 border-r-2"></div>
                  </div>
                }
              </p>
            </div>
            <div>
              {/* <NavLink
              to="/about-us"
              aria-label=""
              className="flex align-baseline  text-xs items-center font-semibold transition-colors duration-200 text-red-900 hover:text-red-800"
            >
              <span>Learn more</span> 
              <svg
                className="inline-block w-2 ml-2 text-xs"
                fill="currentColor"
                viewBox="0 0 12 12"
              >
                <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
              </svg>
            </NavLink> */}
            </div>
          </div>
          <div className="flex items-center justify-center -mx-4 lg:pl-8">
            <div className="flex flex-col items-end px-3">
              <img
                className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
                src={bg3}
                alt=""
              />
              <img
                className="object-cover w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
                src={bg2}
                alt=""
              />
            </div>
            <div className="px-3">
              <img
                className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
                src={bg1}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      {location.pathname !== "/" ? <Footer></Footer> : ""}
    </>
  );
}

export default AboutUsMin;
