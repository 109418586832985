import Footer from "./Footer";

function TermsAndCondition() {
  return (
    <>
      <div className="sm:py-16 mx-auto md:px-24 lg:px-8 lg:py-20 py-[64px] px-5  text-gray-700">
        <h1 className="text-5xl font-extrabold text-center py-5 font-dancing text-red-900">
        Terms and Conditions
        </h1>
       
       
        <p className="mt-2">
          <strong>
            These Terms and Conditions govern your use of SRM Sweets & Cakes
            Classic and the services provided by it. SRM Sweets and Cakes
            provides its services subject to the following terms, conditions,
            and provisions. By accessing, viewing, or using this website,
            <a href="https://www.srmcakesandsweets.com/">
              www.srmcakesandsweets.com
            </a>
            , you are binding to these Terms and Conditions.
          </strong>
        </p>
        <p>
          <strong>
            SRM Sweets and Cakes reserves the right to amend/modify these
            Terms and Conditions at any time.
          </strong>
        </p>
        <ol>
          <li className="mt-2">
            All perishable products like Cakes &amp; Sweets arrangements/design
            / colour, shown here are indicative. We do our best to give a better
            result than shown. It may be possible that the actual arrangement/
            design/colour varies due to availability and other reasons beyond
            our control. In such cases, Customer is not liable for any
            compensation.
          </li>
          <li className="mt-2">
            In cases of ordered cakes / Sweets / other hand delivered gift items
            are not available in the respective city or town, we shall deliver
            available best quality cakes / Sweets / other similar gift items of
            equal value. We always ensure that full or higher value to our
            customers in all such cases.
          </li>
          <li className="mt-2">
            In the case of sweets from a particular brand is not available in
            the city, the same shall be delivered through courier. Shipment
            shall be done in 2 to 5 business days
          </li>
          <li className="mt-2">
            In rare cases after placing the order, the delivery may not be
            possible due to remote area, long-distance, non-availability of the
            product, Non-availability of service either temporarily/
            permanently, a delivery person met with an accident, damage of the
            product, strike, protest, rain, natural calamity, lack of
            transportation. In such case, SRM Sweets and Cakes has full
            authority to take the decision to cancel the order and a full refund
            shall be done. No compensation shall be done for such cancellation.
          </li>
          <li className="mt-2">
            SRM Sweets and Cakes will always try to deliver the product
            within the specific date &amp; time selected by the customer. In
            some cases, earlier or delay may happen and no complaint or
            compensation shall be accepted.
          </li>
          <li className="mt-2">
            Delivery of certain items may not be possible under certain
            uncontrollable circumstances. However, you always have a right to
            get a 100% refund of your money for such items.
          </li>
          <li className="mt-2">
            In some cases gift message may not be written on the cake, gift tag
            during the festivals Occasion, normal days or without the knowledge
            of Bakery, Shop, Delivery Executive. In such cases, Customer cannot
            claim for any compensation.
          </li>
          <li className="mt-2">
            Requests for cancellation of orders will not be entertained after
            processing the order. however, in rare cases, it can be considered.
            The decision of SRM Sweets and Cakes will be final in this
            case.
          </li>
          <li className="mt-2">
            We reserve the right to cancel any order without providing any
            reason. This will be regardless of whether the payment has been
            confirmed/received. Payment for the cancelled orders will be
            refunded and the Customer will be informed. No compensation shall be
            done for the cancelled order.
          </li>
          <li className="mt-2">
            Refund for Cancellation of Order either by the Customer or by Sri
            SRM Sweets & Cake will be settled at the earliest as soon as the
            formal procedure is completed. In some cases, the delay may happen
            due to Payment gateway / Bank formalities. In such cases Customer
            can send an email about non-credit of payment and SRM Sweets & Cakes
            Classic will do all the coordination to get back the amount. No
            compensation shall be done for such a delay.
          </li>
          <li className="mt-2">
            For orders in which problem arises owing to the mistake of the
            Customer e.g. wrong address or incomplete information or recipient
            not reachable, no refund will be given. Redelivery charges, if any,
            will be borne by the Customer.
          </li>
          <li className="mt-2">
            It is the sole responsibility of the Customer to check the order
            detail including product, address, date, time in the order
            confirmation received through email.
          </li>
          <li className="mt-2">
            SRM Sweets and Cakes is not responsible for any Human or
            technical error made by the Customer. For such cases the customer
            should call/email about the any error / changes in the
            product/address/mobile. Any changes in Product / address/error shall
            be considered only after getting email confirmation from Sri
            SRM Sweets & Cake.
          </li>
          <li className="mt-2">
            The Customer agrees to give the correct information which is true
            and authentic. We reserve the right to confirm the information
            provided by the Customer.
          </li>
          <li className="mt-2">
            Any complaint regarding quality of product, non compliance of
            product description, shortage of quantity, damage shall be reported
            within 2 days for the Perishable product and 7 days for the other
            product. SRM Sweets and Cakes have full authority to
            accept/reject including refund for the complaint raised by the
            customer after the mentioned days{" "}
          </li>
          <li className="mt-2">
            Due to the nature of product, services &amp; Marketing strategy
            involved, we have not displayed the shipping &amp; Service charges
            seperately. In such cases the MRP is not applicable due the said
            reason and customer should not claim for the difference in amount
            between MRP displayed in the product &amp; order value. Such
            complaint/clarification are not entertained and SRM Sweets & Cakes
            Classic not liable for the claim. Customer should read the product
            weight / Quantity before placing the order.
          </li>
          <li className="mt-2">
            We will not be held liable for any credit card fraud that occurs on
            its website. Customer have to use only their card when they make
            their payments. The liability of using a fraudulent credit card lies
            on the Customer and the responsibility to establish otherwise shall
            exclusively be the Customer’s
          </li>
          <li className="mt-2">
            We will not be responsible for any delay / non-delivery of the order
            because of flood, fire, wars or for any reason that are beyond our
            control. For such orders a complete refund will be provided.
          </li>
          <li className="mt-2">
            In some cases the shipment of product shall be delayed due to back
            order / Manufacturing time. In such cases Customer are not liable to
            claim any compensation for the delay.
          </li>
          <li className="mt-2">
            There may be delay in delivering the product after shipment due to
            the concern courier company / difficult to identify the location /
            remote area. In such cases SRM Sweets and Cakes shall do all
            the coordination to deliver the product at the earliest. Customer is
            not liable to clain any compensation for the delay.
          </li>
          <li className="mt-2">
            In case of any complaint against the quality or different in
            product. In such cases, the Customer raises the complaint through
            email within 24 hours on receiving the product. SRM Sweets & Cakes
            Classic have full authority to accept or reject the complaint.
            Refund can also be made depending on nature of complaint and any
            decision to refund is sole prerogative of SRM Sweets and Cakes
          </li>
          <li className="mt-2">
            In case a customer wants to cancel an order he needs to do it at
            least 24 Hours before the date of delivery or the date of shipment.
            However in all such cases 10% of the ordered amount will be deducted
            towards credit card processing expenses.
          </li>
          <li className="mt-2">
            The price of the product which are subject to MRP restrictions are
            being sold on the website after adding up costs of our services,
            delivery and online transactions cost. It is the customer
            responsibility to check the price of the product before placing the
            order and they don’t have any liability to claim the difference in
            additional price.
          </li>
          <li className="mt-2">
            Fresh Baked cakes are available only after 11am from bakeries. Thus
            fresh baked cakes can only be delivered after that time. In
            exceptional cases you may request to keep the cake prior day in
            refrigerator &amp; deliver next morning. But we don't advise this.
            If the Cakes, Sweets and Savouries of particular shop is not
            available at the time of delivery or delayed , the company have
            authorized to deliver Cakes &amp; Sweets from different shop of
            equivalent value without informing to the customer
          </li>
          <li className="mt-2">
            If you wish to select from a particular shop for Sweets, Cakes and
            Savouries, the shop will be responsible for the quality of product.
            You can lodge complaints on them through us, if the quality is not
            found up to the mark.
          </li>
          <li className="mt-2">
            We have provided the Delivery Date &amp; Time during the order
            process and it is only tentative. We will do our best to deliver it
            on the schedule date and time. However if there is any delay due to
            difficulties in manufacturing the product, local condition, the
            product shall be delivered on the next day or as soon as it is
            available and the Customer cannot claims any responsibility for the
            delay
          </li>
          <li className="mt-2">
            All the courier product that is not available locally or
            manufacture/purchase in different location shall be delivered
            through courier / speed post. . The time may vary depend on the
            product and delivery location. The schedule date &amp; time is not
            applicable for the courier product.
          </li>
          <li className="mt-2">
            Orders received after 2 Pm IST shall normally be processed as next
            day orders, but we shall try our best to deliver same day. A phone
            call to us after placement of such urgent orders will be
            appreciated.
          </li>
          <li className="mt-2">
            All the product shown in the website including Perishable, Apparels,
            Leather goods, Electronics, Mobile, Toys, Fashion Jewellery etc.
            have been taken using high clarity photo graphic view. The color may
            slightly differ based on the available illumination at the customer
            end.
          </li>
          <li className="mt-2">
            It is the Customer responsibility to check physical damage on the
            product / parcel / while receiving the product through courier. Any
            damage, Quality, difference in product received by the Customer
            through courier shall be intimated by the Customer within 48 hours
            on receiving the product. In such cases SRM Sweets and Cakes
            shall do all necessary means to redeliver the correct product. No
            compensation shall be done in such cases and Customer should liable
            to accept the time taken to redeliver the product.
          </li>
          <li className="mt-2">
            Customer should carefully read the description of the Product,
            shipping details and destination disclaimer information before
            placing the order.
          </li>
          <li className="mt-2">
            The Schedule Date &amp; Time are not applicable for the product
            delivered through Courier. SRM Sweets and Cakes will do the
            coordination with the Courier associates in order to delivered to
            the nearest date selected by the Customer. The Customer do not have
            any liable to complaint regarding delivery date for such product.
          </li>
        </ol>
      </div>
      <Footer></Footer>
    </>
  );
}

export default TermsAndCondition;
