import Footer from "./Footer";

function CancellationPolicy() {
  return (
    <>
      <div className="sm:py-16 mx-auto md:px-24 lg:px-8 lg:py-20 py-[64px] px-5  text-gray-700">
        <h1 className="text-5xl font-extrabold text-center py-5 font-dancing text-red-900">
         Refund & Cancellation Policy
        </h1>

        <p className="text-justify">          
            SRM Sweets and Cakes believes in helping its customers as far
            as possible, and has therefore a liberal cancellation policy. Under
            this policy:       
       
          
            Cancellations will be considered only if the request is made
            within 24hours of placing an order. However, the cancellation
            request will not be entertained if the orders have been communicated
            to the vendors/merchants and they have initiated the process of
            shipping them.
         
       
          
            There is no cancellation of orders placed under the Same Day
            Delivery category.
        
          
            No cancellations are entertained for those products that the Sri
            SRM Sweets & Cake marketing team has obtained on special
            occasions like Pongal, Diwali, and Valentine’s Day or other
            festival, personalize gift etc. These are limited occasion offers
            and therefore cancellations are not possible.{" "}
         
     
          
            SRM Sweets and Cakes does not accept cancellation requests
            for perishable items like flowers, eatables etc. However,
            refund/replacement can be made if the customer establishes that the
            quality of product delivered is not good.
       
          
            In case of receipt of damaged or defective consumer durable items
            and non durable items like mobile phones, mixer grinder, calculator
            etc, please report the same to our Customer Service team. The
            request will, however, be entertained once the merchant has checked
            and determined the same at his own end. This should be reported
            within 7 days of receipt of the products.
         
       
          
            In case you feel that the product received is not as shown on the
            site or as per your expectations, you must bring it to the notice of
            our customer service within 24 hours of receiving the product. The
            Customer Service Team after looking into your complaint will take an
            appropriate decision.
       
            In case of complaints regarding products that come with a warranty
            from manufacturers, please refer the issue to them.
         
        </p>
        <h3 className="font-extrabold my-4">How do I cancel my order?</h3>
        <p>
          
            You can cancel your order online before the product has been
            Purchased / shipped. Your entire order amount will be refunded. An
            order cannot be cancelled once the item has been Purchased / Shipped
            / delivered to you
         
        </p>
        <p>
          
            In order to cancel an order / Product, write an email to
            info@srmcakesandsweets.com with your Order no. and reason to
            cancel the order. We will be sending a confirmation email for the
            cancelled order.
         
        </p>
        <h3 className="font-extrabold my-4">
          Can I cancel product with the same day delivery?
        </h3>
        <p>
          
            It is not entertain to cancel the same day delivery. In such case
            you can call our Customer care +91-9894198280 immediately after
            placing the order with the cancellation email to
            info@srmcakesandsweets.com. The cancellation will be accepted
            subject to the confirmation email from SRM Sweets and Cakes.
         
        </p>
        <h3 className="font-extrabold my-4">
          How long will it take to process my cancellation request?
        </h3>
        <p>
          
            Once you request the cancellation of item(s) in your order, it will
            take us a maximum of 1-2 business days to cancel the order and
            initiate a refund. You will be notified of the same by email.
         
        </p>
        <h3 className="font-extrabold my-4">
          When I will get back the amount for the cancelled order??
        </h3>
        <p>
          
            Once the order is cancelled the refund will be initiated within 2
            days and it may take up to 7-10 business days for the respective
            banks to process the refund. Please get in touch with the banks
            directly in case of any delays post confirmation of
            cancellation/refund by SRM Sweets and Cakes.
         
        </p>
        <h1 class="page-subheading">Refund Policy</h1>
        <p>
          
            When you buy our products/services, your purchase is covered by our
            7-day money-back guarantee. If you are, for any reason, not entirely
            happy with your purchase, we will cheerfully issue a full refund. We
            develop and sell software that we use ourselves every day and have
            thousands of satisfied customers worldwide, and our support is
            second to none. That is why we can afford to back our products with
            this special guarantee. To request a refund, simply contact us with
            your purchase details within Seven (7) days of your purchase. Please
            include your order number (sent to you via email after ordering) and
            optionally tell us why you’re requesting a refund – we take customer
            feedback very seriously and use it to constantly improve our
            products and quality of service. Refunds are not being provided for
            services/ product not meeting our terms &amp; condition. Refunds are
            being processed within 10 days period.
         
        </p>
        <h3 className="font-extrabold my-4">
          I have not got the refunded amount even after 10 days, what I have to
          do?
        </h3>
        <p>
          
            You can write an email to info@srmcakesandsweets.com with your
            Order no. and we will take necessary coordination with our payment
            gateway &amp; your bank to get back the amount.
         
        </p>
      </div>
      <Footer></Footer>
    </>
  );
}

export default CancellationPolicy;
