import React, { useState, useCallback, useEffect, useContext } from "react";
import useRazorpay from "react-razorpay";
import { useNavigate } from "react-router-dom";
import GetApi from "../Services/GetApi";
import { Context } from "../App";
import PostApi from "../Services/PostApi";
import { toast } from "react-toastify";
import bg1 from "./../img/Success.jpg";
function FullCheckout() {
  const [flag , setFlag] = useState(false);
  const [readOnlyFlag,setreadOnlyFlag] = useState(false);
  const { atcProduct, customerID } = useContext(Context);
  const [addressStatus, SetAddressStatus] = useState(2);
  const [AddressList, SetAddressList] = useState([]);
  const [codStatus, SetCodStatus] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [stateatcProduct, setstateatcProduct] = atcProduct;
  const [statecustomerID] = customerID;
  const intilizeValue = {
    customer_no: statecustomerID,
    customer_addr_name: "",
    customer_addr_phone: "",
    customer_addr_email: "",
    customer_addr_locality: "",
    customer_addr_address: "",
    customer_addr_city: "",
    customer_addr_state: "",
    customer_addr_pincode: "",
    customer_addr_landmark: "",
    customer_addr_altphone: "",
    customer_addr_type: "",
    cc: '',
    status: "1",
    created_at: new Date(),
  };
  const [formValues, setFormValues] = useState(intilizeValue);
  const [selectAddress, setselectAddress] = useState([]);
  const [coupons, setcoupons] = useState("");

  const [couponsList, setCouponsList] = useState([]);
  /* Set Values to form  */
  const handlechange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const saveAddress = (e) => {
    let valid = true;
    let items = document.getElementsByClassName("form-control");
    for (let i = 0; i < items.length; i++) {
      if (items[i].required && !items[i].value) {
        items[i].style.border = "1px solid red";
        valid = false;
      } else {
        items[i].style.border = "1px solid #eee";
      }
    }
    if (valid) {
      PostApi("customerAddress", formValues).then((responce) => {
        SetAddressStatus(2);
        GetApi("customerAddress/" + statecustomerID).then((list) => {
          SetAddressList(list.responceData.data);
          let items = document.getElementsByClassName("form-control");
          for (let i = 0; i < items.length; i++) {
            if (items[i].value) items[i].value = "";
          }
        });
      });
    }
    //SetAddressStatus(4);
  };

  const selectAddressEvent = (list, event) => {
    let items = document.getElementsByClassName("address");
    for (let i = 0; i < items.length; i++) {
      items[i].classList.remove("selected-address");
    }
    event.target.closest(".address").classList.add("selected-address");
    setselectAddress(list);
    GetApi("settingValidpincode/" + list.customer_addr_pincode).then((list) => {
      list.responceData.data.length ? SetCodStatus(true) : SetCodStatus(false);
    });
  };

  useEffect(() => {
    GetApi("customerAddress/" + statecustomerID).then((list) => {
      SetAddressList(list.responceData.data);
    });
  }, [statecustomerID]);

  useEffect(() => {
    GetApi("coupons").then((list) => {
      setCouponsList(list.responceData.data);
    });
  }, []);

  const ProductPrice = (product) => {
    return product.product_info[0].attribute_id.find(
      (o) => o.att_id === product.attribute_id
    ).price;
  };
  const totalCalculation = (atc) => {
    return (
      Number(
        atc.reduce(function (a, b) {
          return a + Number(ProductPrice(b)) * b["quantity"];
        }, 0)
      ) - Number(discountAmount) + "00"
    );
  };

  let navigate = useNavigate();
  const Razorpay = useRazorpay();

  const handlePayment = useCallback(
    (amount, atc, address, type) => {
      if (type === "COD") {
        let data = {
          customer_id: statecustomerID,
          product_id: JSON.stringify(atc),
          delivery_address: JSON.stringify(address),
          payment_mode: "COD",
          order_amount: Number(amount.slice(0, -2)).toFixed(2),
          delivery_status: "1",
          order_status: "1",
          online_transaction_id: "COD",
          status: "1",
          cc: coupons,
          difference : discountAmount,
          created_at: new Date(),
        };
        PostApi("order", data).then((responce) => {
          GetApi("atc/" + customerID).then((e) => {
            setstateatcProduct(e.responceData.data);
          });
          navigate("/order-status");
        });
      } else {
        var customerDetails = JSON.parse(
          localStorage.getItem("customerDetails")
        );
        var options = "";
        try {
          options = {
            key: "rzp_live_HFbsHOoLI5mj76", // Enter the Key ID generated from the Dashboard
            amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "SRM Sweets & Cakes",
            description: `Creating new order from ${customerID} for Rs.${Number(
              amount.slice(0, -2)
            ).toFixed(2)}`,
            image:
              "https://srmcakesandsweets.com/static/media/SVS-1.bc3cc37529dbb510e76d.png",
            handler: function (response) {
              let data = {
                customer_id: statecustomerID,
                product_id: JSON.stringify(atc),
                delivery_address: JSON.stringify(address),
                payment_mode: "RazerPay",
                order_amount: Number(amount.slice(0, -2)).toFixed(2),
                delivery_status: "1",
                order_status: "1",
                online_transaction_id: JSON.stringify(response),
                status: "1",
                created_at: new Date(),
              };
              PostApi("order", data).then((responce) => {
                GetApi("atc/" + customerID).then((e) => {
                  setstateatcProduct(e.responceData.data);
                });
                navigate("/order-status");
              });
              // navigate("/order-status");
            },
            prefill: {
              name: customerDetails?.customer_fname,
              email: customerDetails?.customer_email || "info@svs.com",
              contact: customerDetails?.customer_phone,
            },
            notes: {
              address: "Razorpay Corporate Office",
            },
            theme: {
              color: "#3399cc",
            },
          };
        } catch (error) {
          //console.log(error);
        }

        const rzpay = new Razorpay(options);
        rzpay.on("payment.failed", function (response) {
          //toast.error(response.error.code);
          toast.error(response.error.description);
          // console.log(response.error.source);
          // console.log(response.error.step);
          // console.log(response.error.reason);
          // console.log(response.error.metadata.order_id);
          // console.log(response.error.metadata.payment_id);
        });
        rzpay.open();
      }
    },
    [Razorpay, coupons, customerID, discountAmount, navigate, setstateatcProduct, statecustomerID]
  );
  const checkCoupons = (coupon) => {
    PostApi("validate_coupons", {
      cc: coupon ? coupon : coupons,
      amt: String(
        stateatcProduct.reduce(function (a, b) {
          return a + Number(ProductPrice(b)) * b["quantity"];
        }, 0)
      ),
      customer_no: statecustomerID,
    }).then((data) => {
      if (data?.responcePostData?.data?.status_code !== '200') {
        toast.error(data?.responcePostData?.data?.message || 'Coupon Not exits')
        clearCoupons();
      }
      else {
        setDiscountAmount(Number(data?.responcePostData?.data?.difference || 0));
        //toast.success("You have save Rs." + (data?.responcePostData?.data?.difference || 0));
        setFlag(true);
        setreadOnlyFlag(true);
      }
    });
  };

  const handleChange = (e) => {
    setcoupons(e.target.value);
  };

  const clearCoupons = () => {
    setreadOnlyFlag(false);
    setcoupons('');
    setDiscountAmount(0);
  }

  const setAndCheck = (readOnlyFlag, coupon) => {
    if(!readOnlyFlag){
      setcoupons(coupon);
      checkCoupons(coupon);
    }  
  }

  return (
    <> <div className="flex items-center justify-center bg-gray-300 text-gray-800 p-2 py-[64px]">
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-8 w-full max-w-screen-lg">
        <div className="lg:col-span-2">
          <h2 className="text-sm font-medium hidden">Payment Method</h2>
          <div className="bg-white rounded mt-4 shadow-lg p-3">
            <button className="flex items-center focus:outline-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={
                  addressStatus === 4 ? "h-5 w-5 text-green-900" : "hidden"
                }
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              <h1 className="mx-4 ml-0 text-sm uppercase font-medium text-gray-700 ">
                Delivery Address
              </h1>
            </button>

            <div className={addressStatus === 2 ? "border-t-2 mt-2" : "hidden"}>
              {AddressList.map((list) => (
                <div key={list.customer_addr_name}
                  className="flex mt-2 border border-b-2 cursor-pointer address"
                  onClick={(e) => selectAddressEvent(list, e)}
                >
                  <div className="items-center px-4 py-5 ">
                    <label className="text-xs font-bold">
                      {list.customer_addr_name}
                    </label>
                    <span className="inline-flex mx-2 items-center justify-center px-2 py-1 text-xs font-bold leading-none text-whit bg-white text-white">
                      {list.customer_addr_type}
                    </span>
                    <label className="text-xs font-bold ml-1">
                      {list.customer_addr_phone}
                    </label>
                    <p className="text-xs font-normal my-2">
                      {list.customer_addr_address} {" , "}{" "}
                      {list.customer_addr_city} {" , "}{" "}
                      {list.customer_addr_state} {" - "}{" "}
                      {list.customer_addr_pincode}
                    </p>
                  </div>
                </div>
              ))}

              <div className=" col-span-2 grid grid-cols-2 gap-4 pt-4">
                <button
                  onClick={() => SetAddressStatus(3)}
                  className="items-center col-span-1 justify-center uppercase bg-blue-600 text-sm font-medium w-full h-10 rounded text-blue-50 hover:bg-blue-700"
                >
                  Add Address
                </button>
              </div>
            </div>
          </div>
          <div
            className={
              addressStatus === 3
                ? "bg-white rounded mt-4 shadow-lg p-3"
                : "hidden"
            }
          >
            <h1 className="mx-4 ml-0 text-sm uppercase font-medium text-gray-700 ">
              Add Delivery Address
            </h1>
            <p className=" text-xs">
              Please select the below listed address or create a new address
            </p>
            <form>
              <div className="border-t mt-1">
                <div className="grid grid-cols-2 gap-4">
                  <div className="col-span-1">
                    <label className="text-xs font-semibold">
                      Name <span className=" text-red-900">*</span>
                    </label>
                    <input
                      className="form-control flex items-center h-10 border mt-1 rounded px-4 w-full text-sm"
                      type="text"
                      onChange={handlechange}
                      required
                      name="customer_addr_name"
                    />
                  </div>
                  <div className="">
                    <label className="text-xs font-semibold">
                      Phone Number <span className=" text-red-900">*</span>
                    </label>
                    <input
                      className="form-control flex items-center h-10 border mt-1 rounded px-4 w-full text-sm"
                      type="text"
                      required
                      onChange={handlechange}
                      name="customer_addr_phone"
                    />
                  </div>
                  <div className="">
                    <label className="text-xs font-semibold">
                      Pincode <span className=" text-red-900">*</span>
                    </label>
                    <input
                      className="form-control flex items-center h-10 border mt-1 rounded px-4 w-full text-sm"
                      type="text"
                      required
                      name="customer_addr_pincode"
                      onChange={handlechange}
                    />
                  </div>
                  <div className="">
                    <label className="text-xs font-semibold">Locality</label>
                    <input
                      className="form-control flex items-center h-10 border mt-1 rounded px-4 w-full text-sm"
                      type="text"
                      onChange={handlechange}
                      name="customer_addr_locality"
                    />
                  </div>
                  <div className="col-span-2">
                    <label className="text-xs font-semibold">
                      Address (Area & Street){" "}
                      <span className=" text-red-900">*</span>
                    </label>
                    <input
                      className="form-control flex items-center h-10 border mt-1 rounded px-4 w-full text-sm"
                      type="text"
                      required
                      onChange={handlechange}
                      name="customer_addr_address"
                    />
                  </div>
                  <div className="">
                    <label className="text-xs font-semibold">
                      City/District/Town{" "}
                      <span className=" text-red-900">*</span>
                    </label>
                    <input
                      className="form-control flex items-center h-10 border mt-1 rounded px-4 w-full text-sm"
                      type="text"
                      required
                      onChange={handlechange}
                      name="customer_addr_city"
                    />
                  </div>
                  <div className="">
                    <label className="text-xs font-semibold">
                      State <span className=" text-red-900">*</span>
                    </label>
                    <input
                      className="form-control flex items-center h-10 border mt-1 rounded px-4 w-full text-sm"
                      type="text"
                      required
                      onChange={handlechange}
                      name="customer_addr_state"
                    />
                  </div>
                  <div className="">
                    <label className="text-xs font-semibold">
                      Landmark (Optional)
                    </label>
                    <input
                      className="form-control flex items-center h-10 border mt-1 rounded px-4 w-full text-sm"
                      type="text"
                      onChange={handlechange}
                      name="customer_addr_landmark"
                    />
                  </div>
                  <div className="">
                    <label className="text-xs font-semibold">
                      Alternate Phone (Optional)
                    </label>
                    <input
                      className="form-control flex items-center h-10 border mt-1 rounded px-4 w-full text-sm"
                      type="text"
                      onChange={handlechange}
                      name="customer_addr_altphone"
                    />
                  </div>
                  <div className="">
                    <label className="text-xs font-semibold">
                      Email <span className=" text-red-900">*</span>
                    </label>
                    <input
                      className="form-control flex items-center h-10 border mt-1 rounded px-4 w-full text-sm"
                      type="text"
                      required
                      onChange={handlechange}
                      name="customer_addr_email"
                    />
                  </div>
                  <div className=" col-span-1">
                    <label className="text-xs font-semibold">
                      Address Type <span className=" text-red-900">*</span>
                    </label>

                    <select
                      required
                      className="form-control flex items-center h-10 border mt-1 rounded px-4 w-full text-sm"
                      name="customer_addr_type"
                      onChange={handlechange}
                    >
                      <option value="" key="none">
                        Choose From the List
                      </option>
                      <option value="Home" key="Home (All day delivery)">
                        Home (All day delivery)
                      </option>
                      <option
                        value="Work"
                        key="Work (Delivery between 10 AM - 5 PM)"
                      >
                        Work (Delivery between 10 AM - 5 PM)
                      </option>
                    </select>
                  </div>
                  <div className="col-span-2 grid grid-cols-2 gap-4 pt-4">
                    <button
                      type="button"
                      onClick={(e) => saveAddress(e)}
                      className="items-center justify-center uppercase bg-blue-600 text-sm font-medium w-full h-10 rounded text-blue-50 hover:bg-blue-700"
                    >
                      Save Address
                    </button>
                    <button
                      onClick={() => SetAddressStatus(2)}
                      type="reset"
                      className="flex items-center justify-center bg-white text-sm font-medium w-full h-10 rounded border  ml-1 text-black hover:bg-red-700"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div>
          <div className="bg-white rounded mt-4 shadow-lg py-6">
            <div className="px-8">
              <h2 className="text-sm font-bold mb-2">Pirce Details</h2>
              <div className="flex items-end">
                <p className="text-sm font-semibold">
                  Price ({" "}
                  {stateatcProduct.reduce(function (a, b) {
                    return a + b["quantity"];
                  }, 0)}{" "}
                  Item )
                </p>
                <span className="text-sm ml-auto font-semibold">
                  {" "}
                  Rs.
                  {stateatcProduct.reduce(function (a, b) {
                    return a + Number(ProductPrice(b)) * b["quantity"];
                  }, 0)}
                  {".00"}
                </span>
              </div>
            </div>
            <div className="px-8 mt-4">
              <div className="flex items-end justify-between">
                <span className="text-sm font-semibold">Delivery Charges</span>
                <span className="text-sm text-green-500 font-bold mb-px">
                  Free
                </span>
              </div>
            </div>
            <div className="px-8 mt-4 border-t pt-4">
              <div className="flex flex-col ">
                <span className="font-semibold">Coupons</span>
                <div className=" flex items-end justify-between my-2">
                  <input
                    type="text"
                    className={`border border-gray-500 h-8 bg-white p-1 w-2/3 mr-1 ${readOnlyFlag ? 'bg-gray-300 pointer-events-none cursor-not-allowed' : ''}  `}
                    placeholder="Enter your coupon code"
                    id="coupons"
                    value={coupons}
                    onChange={handleChange}
                    disabled={readOnlyFlag}
                  />
                  {readOnlyFlag ?
                  <button
                  onClick={() => clearCoupons()}
                  className="flex items-center justify-center text-sm font-medium h-8 rounded text-white hover:bg-red-700 bg-red-800 w-1/3"
                >
                  Remove
                </button>
               :
                  <button
                    onClick={() => checkCoupons()}
                    className="flex items-center justify-center text-sm font-medium h-8 rounded text-white hover:bg-gold-700 bg-gold-800 w-1/3"
                  >
                    Apply
                  </button>
}
                </div>
                {discountAmount ? <div className="flex items-end justify-between text-md text-green-700 mt-2">
                  <span className="font-semibold ">Discount Amount</span>
                  <span className="font-semibold">
                    Rs.
                    {discountAmount}
                    {".00"}
                  </span>
                </div> : ''}
              </div>
            </div>
            {couponsList.map(item => (
              <div key={item?.cc} className="flex overflow-hidden px-8 mt-2  pt-4 cursor-pointer" onClick={() => setAndCheck(readOnlyFlag, item?.cc) }>
                <div className="flex p-3 w-1/3 justify-center items-center uppercase text-center bg-gold-900 text-white">
                  {item.det_type === 'flat' ? `Flat Rs.${item.value}` : `${item.value}%`}
                </div>
                <div className="px-3 w-2/3 py-2 border-2 border-gold-900">
                  <div className="">
                    <span className="font-semibold text-red-900 dark:text-yellow-300">{item.cc}</span>
                    <p className="text-sm text-gray-600 dark:text-gray-200">
                      {item.description}
                    </p>
                  </div>
                </div>
              </div>
            )
            )}

            <div className="px-8 mt-4 border-t pt-4">
              <div className="flex items-end justify-between">
                <span className="font-semibold">Total</span>
                <span className="font-semibold">
                  Rs.
                  {stateatcProduct.reduce(function (a, b) {
                    return a + Number(ProductPrice(b)) * b["quantity"];
                  }, 0) - discountAmount}
                  {".00"}
                </span>
              </div>
            </div>

            <div className="flex flex-col px-8 pt-4">
              <button
                onClick={() =>
                  handlePayment(
                    totalCalculation(stateatcProduct),
                    stateatcProduct,
                    selectAddress,
                    "RazerPay"
                  )
                }
                className={
                  customerID !== null && typeof selectAddress.id === "string"
                    ? "flex items-center justify-center bg-blue-600 text-sm font-medium w-full h-10 rounded text-blue-50 hover:bg-blue-700"
                    : " flex items-center justify-center  text-sm font-medium w-full h-10 rounded text-blue-50 hover:bg-blue-700 bg-slate-500 pointer-events-none"
                }
              >
                Make Payment
              </button>
              <button
                onClick={() =>
                  handlePayment(
                    totalCalculation(stateatcProduct),
                    stateatcProduct,
                    selectAddress,
                    "COD"
                  )
                }
                className={
                  customerID !== null && codStatus
                    ? "flex items-center justify-center mt-1 bg-blue-600 text-sm font-medium w-full h-10 rounded text-blue-50 hover:bg-blue-700"
                    : " flex items-center justify-center mt-1  text-sm font-medium w-full h-10 rounded text-blue-50 hover:bg-blue-700 bg-slate-500 pointer-events-none"
                }
              >
                Cash On Delivery
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
      <div className={`relative z-10 text-center ${ !flag ? 'hidden' :''} `} aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="mt-3 text-center sm:mt-0 sm:text-center">
                  <div className="mt-2">
                    <p className="text-xl text-red-900">You have save</p>
                    <p className=" font-semibold text-2xl text-red-900">Rs.{discountAmount}</p>
                    <img
                      src={bg1}
                      alt=""
                      className="w-full h-full object-center object-cover"
                    />
                  </div>
                  <button type="button" onClick={()=> setFlag(false)} className="mt-3 inline-flex w-full justify-center rounded-md border border-gold-300 bg-gold px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0  sm:w-auto sm:text-sm">Okay</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default FullCheckout;
