import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import GetApi from "../Services/GetApi";

function Footer() {
  const [category, setcategory] = useState([]);
  const [details, setDetails] = useState([]);
  useEffect(() => {
    GetApi("category").then((e) => {
      setcategory(e.responceData.data);
    });
  }, []);
  useEffect(() => {
    GetApi("settings").then((e) => {
      setDetails(e.responceData.data[0]);
    });
  }, []);
  return (
    <div className=" hidden sm:block relative bg-red-900">
      <div className="px-4 pt-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        {category.length ? (
          <div className="flex gap-5 w-full">
            <div className="w-4/6">
              <a
                href="/"
                aria-label="Go home"
                title="Company"
                className="inline-flex items-center"
              >
                <svg
                  className="w-8 text-white"
                  viewBox="0 0 24 24"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  stroke="currentColor"
                  fill="none"
                >
                  <rect x="3" y="1" width="7" height="12" />
                  <rect x="3" y="17" width="7" height="6" />
                  <rect x="14" y="1" width="7" height="6" />
                  <rect x="14" y="11" width="7" height="12" />
                </svg>
                <span className="ml-2 text-xl font-bold tracking-wide text-white uppercase">
                  SRM Sweets and Cakes
                </span>
              </a>
              <div className="my-4">
                <p className="text-xs text-deep-purple-50">{details.aboutus}</p>
              </div>
            </div>

            <div className="text-xs w-1/6">
              <p className="font-semibold tracking-wide text-white">About</p>
              <ul className="mt-2 space-y-2">
                <li>
                  <Link
                    to={"/about-us"}
                    className="transition-colors duration-300 text-deep-purple-50 hover:text-white flex"
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/contact-us"}
                    className="transition-colors duration-300 text-deep-purple-50 hover:text-white flex"
                  >
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/terms-and-condition"}
                    className="transition-colors duration-300 text-deep-purple-50 hover:text-white flex"
                  >
                    Terms & Conditions
                  </Link>
                </li>

                <li>
                  <Link
                    to={"/privacy-policy"}
                    className="transition-colors duration-300 text-deep-purple-50 hover:text-white flex"
                  >
                    Privacy Policy
                  </Link>
                </li>
               
                <li>
                  <Link
                    to={"/refund-and-cancellation-policy"}
                    className="transition-colors duration-300 text-deep-purple-50 hover:text-white flex"
                  >
                    Refund & Cancellation Policies
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/shipping-delivery"}
                    className="transition-colors duration-300 text-deep-purple-50 hover:text-white flex"
                  >
                    Shipping & Delivery
                  </Link>
                </li>
              </ul>
            </div>
            <div className="text-xs w-1/6">
              <p className="font-semibold tracking-wide text-white">
                Category
              </p>
              <ul className="mt-2 space-y-2">
                {category.map((e) =>
                  e.status == "1" ? (
                    <Link
                      key={Math.random()}
                      to={"shop/" + e.category_no}
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-white flex"
                    >
                      {e.category_name}
                    </Link>
                  ) : (
                    ""
                  )
                )}
              </ul>
            </div>
            <div className="text-sm w-2/6">
              <p className="font-semibold text-xs tracking-wide text-white">
                Contact Us
              </p>
              <ul className="mt-2 space-y-2 text-xs">
                <li className="transition-colors duration-300 text-deep-purple-50 hover:text-white flex">
                  379/5, Midland Street,
                  <br /> Kalarampatti Mani Road,
                  <br /> Salem - 636015.
                </li>
                <li className="transition-colors duration-300 text-deep-purple-50 hover:text-white flex">
                  <a href="tel:+919600619991">+91 96006 19991</a>
                </li>
                <li className="transition-colors duration-300 text-deep-purple-50 hover:text-white flex">
                  <a href="mailto:info@srmcakesandsweets.com">
                    info@srmcakesandsweets.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="flex flex-col justify-between pt-5 pb-10 border-t border-deep-purple-accent-200 sm:flex-row">
          <p className="text-sm text-gray-100">
            © Copyright 2022. All rights reserved.
          </p>
          <div className="flex items-center mt-4 space-x-4 sm:mt-0">
            <a
              href="/"
              className="transition-colors duration-300 text-deep-purple-100 hover:text-white"
            >
              <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
              </svg>
            </a>
            <a
              href="/"
              className="transition-colors duration-300 text-deep-purple-100 hover:text-white"
            >
              <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                <circle cx="15" cy="15" r="4" />
                <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
              </svg>
            </a>
            <a
              href="/"
              className="transition-colors duration-300 text-deep-purple-100 hover:text-white"
            >
              <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
