import React, { useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { UtilsJson } from "../utils/UtilsJson";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Context } from "../App";
import PostApi from "../Services/PostApi";
import DeleteApi from "../Services/DeleteApi";
import GetApi from "../Services/GetApi";

export default function QuickViewPopUp() {
  const {
    QuickProductView,
    QuickProductViewData,
    customerID,
    atcProduct,
    loginPopUpView,
  } = useContext(Context);
  const [stateQuickProductView, setstateQuickProductView] = QuickProductView;
  const [stateQuickProductViewData] = QuickProductViewData;
  const [statecustomerID] = customerID;
  const [stateatcProduct, setstateatcProduct] = atcProduct;
  const [, setstateloginPopUpView] = loginPopUpView;
  const [selectAttr, setSelectAttr] = useState({});
  useEffect(() => {
    var flag = true;
    if (typeof stateQuickProductViewData?.attribute_id === "object") {
      stateQuickProductViewData?.attribute_id.forEach((element) => {
        if (element.price && flag) {
          setSelectAttr(element);
          flag = false;
        }
      });
    }
  }, [stateQuickProductViewData]);
  function setToCart(params,attr) {
    if (customerID[0] === null) {
      setstateloginPopUpView(true);
    } else {
      let addCart = {
        customer_id: statecustomerID,
        product_code: params.product_code,
        quantitystatus: "Inc",
        attribute_id:attr.att_id        
      };
      PostApi("atcUpdate", addCart).then((list) => {
        setstateatcProduct(list.responcePostData.data);
        // toast.success(params.product_name + " add to the cart successfully!!", {
        //   position: "bottom-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
      });
    }
  }

  function checkAtcBagState(...params) {
    let atc = params[0];
    return atc.findIndex((x) => (x.product_code === params[1] && x.attribute_id === params[2])) === -1
      ? false
      : true;
  }

  function checkProduct(...params) {
    let atc = params[0];
    return atc.find((x) => (x.product_code === params[1] && x.attribute_id === selectAttr.att_id));
  }

  const updateProduct = (type, id, count, pid,attr) => {
    let addCart = {
      customer_id: statecustomerID,
      product_code: id,
      quantitystatus: type,      
      attribute_id:attr.att_id  
    };

    count === 1 && type === "Dec"
      ? removeProduct(pid)
      : PostApi("atcUpdate", addCart).then((list) => {
          setstateatcProduct(list.responcePostData.data);
        });
  };

  const removeProduct = (id) => {
    DeleteApi("atc/" + id).then(() => {
      GetApi("atc/" + statecustomerID).then((e) => {
        setstateatcProduct(e.responceData.data);
      });
    });
  };

  return (
    <>
      <Transition.Root show={stateQuickProductView}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          onClose={() => setstateQuickProductView(false)}
        >
          <div
            className="flex justify-center min-h-screen bg-slate-400/[.70] pt-4 px-4 pb-20 text-center sm:block sm:p-0"
            style={{ fontSize: 0 }}
          >
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="hidden fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity md:block" />
            </Transition.Child>

            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              {stateQuickProductView ? (
                <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-xl md:px-4 md:my-8 md:align-middle lg:max-w-lg">
                  <div className=" w-screen relative flex items-center bg-white overflow-hidden shadow-2xl">
                    <button
                      type="button"
                      className="absolute top-4 right-4 text-gray-800 hover:text-gray-500 sm:top-8 sm:right-6 md:top-3 md:right-3"
                      onClick={() => setstateQuickProductView(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon
                        className="h-6 w-6 text-black"
                        aria-hidden="true"
                      />
                    </button>

                    <div className="w-full flex flex-row items-start sm:grid-cols-12 lg:gap-x-8">
                      <div className="rounded-t-sm  rounded-r-sm bg-gray-100 overflow-hidden sm:col-span-12 lg:col-span-12">
                        <img
                          src={
                            UtilsJson.baseUrl +
                            "productimg/" +
                            stateQuickProductViewData.product_img
                          }
                          alt={stateQuickProductViewData.product_img}
                          className="w-full"
                        />
                      </div>
                      <div className="col-span-12 p-3">
                        <h2 className="text-2xl md:text-4xl text-center  font-bold text-red-800   font-dancing">
                          {stateQuickProductViewData.product_name}
                        </h2>
                        <section
                          aria-labelledby="options-heading"
                          className="text-center"
                        >
                          <div className="flex justify-center">
                            {stateQuickProductViewData?.attribute_id.map(
                              (element) =>
                                element.price ? (
                                  <span  key={Math.random()}
                                    className={`border-2 p-2 m-2 cursor-pointer text-xs text-slate-400 ${(selectAttr.att_id === element.att_id)? 'border-2 border-gold-50' :''}`}
                                    onClick={() => setSelectAttr(element)}
                                  >
                                    {element.att_value}
                                  </span>
                                ) : (
                                  ""
                                )
                            )}
                          </div>
                          <p className="text-xs my-2 font-normal text-center text-blue-900 md:text-lg ">
                            Rs.
                            {Number(selectAttr.price).toFixed(2)}
                          </p>
                          {!checkAtcBagState(
                            stateatcProduct,
                            stateQuickProductViewData.product_code,
                            selectAttr.att_id
                          ) ? (
                            <>
                              <button
                                onClick={() => setToCart(stateQuickProductViewData,selectAttr)}
                                className="px-1 py-2 sm:px-2 mt-1 text-xs w-full text-white uppercase transition-colors duration-200 transform bg-red-800 rounded  hover:bg-red-700 dark:hover:bg-gray-600 focus:outline-none focus:bg-gray-700 dark:focus:bg-gray-600"
                              >
                                Add to Cart
                              </button>
                            </>
                          ) : (
                            <>
                              <p className="text-gray-500 flex flex-row w-full mt-2 justify-center ">
                                <button
                                  className={
                                    "border-2 border-red-800 rounded-tl-sm rounded-bl-sm px-3 sm:px-3 sm:py-1 cursor-pointer bg-red-800 text-white"
                                  }
                                  onClick={() =>
                                    updateProduct(
                                      "Dec",
                                      stateQuickProductViewData.product_code,
                                      checkProduct(
                                        stateatcProduct,
                                        stateQuickProductViewData.product_code,
                                      ).quantity,
                                      checkProduct(
                                        stateatcProduct,
                                        stateQuickProductViewData.product_code
                                      ).id,
                                      selectAttr
                                    )
                                  }
                                >
                                  -
                                </button>
                                <input
                                  className="border-2 border-red-800 w-10 text-center p-1 text-black"
                                  readOnly
                                  type="text"
                                  value={
                                    checkProduct(
                                      stateatcProduct,
                                      stateQuickProductViewData.product_code
                                    ).quantity
                                  }
                                />{" "}
                                <button
                                  onClick={() =>
                                    updateProduct(
                                      "Inc",
                                      stateQuickProductViewData.product_code,
                                      checkProduct(
                                        stateatcProduct,
                                        stateQuickProductViewData.product_code
                                      ).quantity,
                                      checkProduct(
                                        stateatcProduct,
                                        stateQuickProductViewData.product_code
                                      ).id,selectAttr
                                    )
                                  }
                                  className="border-2 border-red-800 rounded-tr-sm rounded-br-sm px-3 m-0 sm:px-3 sm:py-1 cursor-pointer bg-red-800 text-white"
                                >
                                  +
                                </button>
                              </p>
                            </>
                          )}
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
