import React, { useContext, useState, useEffect } from "react";
import { Context } from "../App";
import { UtilsJson } from "../utils/UtilsJson";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PostApi from "../Services/PostApi";

function ProductList() {
  const { QuickProductView, QuickProductViewData, ProductList } = useContext(Context);
  const [, setstateQuickProductView] = QuickProductView;
  const [, setstateQuickProductViewData] = QuickProductViewData;
  const [Trending, setTrending] = useState([]);
  const [NewArrival, setNewArrival] = useState([]);
  const [ProductLists] = ProductList;
  useEffect(() => {
    PostApi("tagwiseproducts/TAG10001").then((e) => {
      setTrending(e.responcePostData.data);
    });
    PostApi("tagwiseproducts/TAG10003").then((e) => {
      setNewArrival(e.responcePostData.data);
    });
  }, []);

  function productListData(product, title) {
    return <section className=" ">
      <div className="container px-2  mx-auto sm:px-6 pb-14 sm:my-auto">
        <h1 className="text-2xl sm:text-6xl mb-4 sm:mb-10 font-semibold text-red-800 font-dancing capitalize text-center lg:text-6xl ">
          explore our <br /> {title + " "}
          <span className="underline decoration-orange-900 font-dancing">
            Products
          </span>
        </h1>
        <div className="grid grid-cols-2 gap-y-4 gap-x-2 md:grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 xl:gap-x-4">
          {product.map((e) => (

            <div
              key={Math.random()}
              className="flex flex-col max-w-md mx-auto overflow-hidden bg-white rounded-lg cursor-pointer shadow-lg hover:shadow-2xl "
            >
              <div className="w-12/12 shadow-lg">
                <img
                  onClick={() => {
                    setstateQuickProductView(true);
                    setstateQuickProductViewData(e);
                  }}
                  src={
                    UtilsJson.baseUrl + "productimg/" + e.product_img
                  }
                  className="w-full h-full bg-cover object-cover object-center"
                  alt={e.product_name}
                />
              </div>
              <div className="w-12/12 p-2 sm:p-4 flex flex-col justify-between h-full">
                <h1 className="text-md md:text-xl text-center font-bold text-red-900 font-dancing w-full overflow-hidden text-ellipsis">
                  {e.product_name}
                </h1>
                <div className="flex justify-between mt-1 item-center flex-col">
                  <h1 className="text-xs font-normal text-blue-900 text-center w-full  md:text-lg">
                    Rs{" "}
                    {Number(
                      e?.attribute_id[0]?.price ||
                      e?.attribute_id[1]?.price ||
                      e?.attribute_id[2]?.price ||
                      0
                    ).toFixed(2)}
                  </h1>
                  <button
                    onClick={() => {
                      setstateQuickProductView(true);
                      setstateQuickProductViewData(e);
                    }}
                    className="px-1 py-2 sm:px-2 mt-1 text-xs w-full text-white uppercase transition-colors duration-200 transform bg-red-900 rounded  hover:bg-red-700 dark:hover:bg-gray-600 focus:outline-none focus:bg-gray-700 dark:focus:bg-gray-600"
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  }

  return (
    <>
      {productListData(Trending, "Trending")}
      {productListData(NewArrival, "New Arrival")}
      {productListData(ProductLists, "Awesome")}
    </>
  );
}

export default ProductList;
